import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce, Events } from 'icos-cp-utils';
import Tabs from '../../components/ui/Tabs';
import SearchResultRegular from './SearchResultRegular';
import { updateCheckedObjectsInSearch, switchTab, filtersReset, setMapProps } from '../../actions/search';
import { getLastSegmentsInUrls, isSmallDevice } from '../../utils';
import { addToCart, updateRoute } from "../../actions/common";
import Filters from "./Filters";
import SearchResultCompact from "./SearchResultCompact";
import Advanced from "./Advanced";
import config from '../../config';
import { getPersistedMapProps } from '../../backend';
import { addingToCartProhibition } from '../../models/CartItem';
class Search extends Component {
    events;
    handleResize;
    persistedMapProps;
    constructor(props) {
        super(props);
        this.events = new Events();
        this.handleResize = debounce(() => {
            const expandedFilters = this.state.expandedFilters;
            const expanded = isSmallDevice()
                ? expandedFilters
                : true;
            this.setState({ expandedFilters: expanded });
        });
        this.events.addToTarget(window, "resize", this.handleResize);
        this.persistedMapProps = getPersistedMapProps() ?? {
            baseMap: config.olMapSettings.defaultBaseMap,
            srid: config.olMapSettings.defaultSRID
        };
        this.state = {
            expandedFilters: !isSmallDevice(),
            srid: this.persistedMapProps.srid
        };
    }
    handlePreview(urls) {
        this.props.updateRoute('preview', getLastSegmentsInUrls(urls));
    }
    handleAddToCart(objInfo) {
        this.props.addToCart(objInfo);
        this.props.updateCheckedObjects([]);
    }
    handleAllCheckboxesChange() {
        if (this.props.checkedObjectsInSearch.length > 0) {
            this.props.updateCheckedObjects([]);
        }
        else {
            const checkedObjects = this.props.objectsTable
                .filter(o => addingToCartProhibition(o).allowCartAdd)
                .map(o => o.dobj);
            this.props.updateCheckedObjects(checkedObjects);
        }
    }
    handleFilterReset() {
        delete this.persistedMapProps.drawFeatures;
        this.props.filtersReset();
    }
    updatePersistedMapProps(persistedMapProps) {
        this.persistedMapProps = { ...this.persistedMapProps, ...persistedMapProps };
        this.props.setMapProps(this.persistedMapProps);
    }
    updateMapSelectedSRID(srid) {
        const { isStationFilterCtrlActive, baseMap, visibleToggles } = this.persistedMapProps;
        this.persistedMapProps = {
            isStationFilterCtrlActive,
            baseMap,
            visibleToggles,
            srid
        };
        // Using srid as key for SearchResultMap forces React to recreate the component when it changes
        this.setState({ srid });
    }
    toggleFilters() {
        this.setState({ expandedFilters: !this.state.expandedFilters });
    }
    componentWillUnmount() {
        this.events.clear();
    }
    render() {
        const { HelpSection, tabs, switchTab } = this.props;
        const { srid } = this.state;
        const expandedFilters = this.state.expandedFilters ? {} : { height: 0, overflow: 'hidden' };
        const filterIconClass = this.state.expandedFilters ? "fas fa-angle-up float-end" : "fas fa-angle-down float-end";
        return (React.createElement("div", { className: "row", style: { position: 'relative' } },
            React.createElement("div", { style: { display: 'inline-block' } }, HelpSection),
            React.createElement("div", { className: "col-sm-4 col-md-3", style: { marginBottom: 20 } },
                React.createElement("button", { className: "btn btn-outline-secondary w-100 d-block d-sm-none", type: "button", onClick: this.toggleFilters.bind(this), style: { marginBottom: 10 } },
                    "Filters",
                    React.createElement("span", { className: filterIconClass, "aria-hidden": "true", style: { marginTop: 2 } })),
                React.createElement("div", { style: expandedFilters },
                    React.createElement(Tabs, { tabName: "searchTab", selectedTabId: tabs.searchTab, switchTab: switchTab },
                        React.createElement(Filters, { tabHeader: "Filters", handleFilterReset: this.handleFilterReset.bind(this) }),
                        React.createElement(Advanced, { tabHeader: "Advanced" })))),
            React.createElement("div", { className: "col-sm-8 col-md-9" },
                React.createElement(Tabs, { tabName: "resultTab", selectedTabId: tabs.resultTab, switchTab: switchTab },
                    React.createElement(SearchResultRegular, { tabHeader: "Search results", handlePreview: this.handlePreview.bind(this), handleAddToCart: this.handleAddToCart.bind(this), handleAllCheckboxesChange: this.handleAllCheckboxesChange.bind(this) }),
                    React.createElement(SearchResultCompact, { tabHeader: "Compact view", handlePreview: this.handlePreview.bind(this) })))));
    }
}
function stateToProps(state) {
    return {
        checkedObjectsInSearch: state.checkedObjectsInSearch,
        objectsTable: state.objectsTable,
        tabs: state.tabs
    };
}
function dispatchToProps(dispatch) {
    return {
        updateRoute: (route, previewPids) => dispatch(updateRoute(route, previewPids)),
        addToCart: (ids) => dispatch(addToCart(ids)),
        updateCheckedObjects: (ids) => dispatch(updateCheckedObjectsInSearch(ids)),
        switchTab: (tabName, selectedTabId) => dispatch(switchTab(tabName, selectedTabId)),
        setMapProps: (mapProps) => dispatch(setMapProps(mapProps)),
        filtersReset: () => dispatch(filtersReset)
    };
}
export default connect(stateToProps, dispatchToProps)(Search);
