import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setNumberFilter, setFilterTemporal, specFilterUpdate, setKeywordFilter, setMapProps } from "../../actions/search";
import { PanelsWithFilters } from "../../components/filters/PanelsWithFilters";
import MapFilter from '../../components/filters/MapFilter';
import { getPersistedMapProps } from '../../backend';
import config from '../../config';
class Filters extends Component {
    persistedMapProps;
    constructor(props) {
        super(props);
        this.persistedMapProps = getPersistedMapProps() ?? {
            baseMap: config.olMapSettings.defaultBaseMap,
            srid: config.olMapSettings.defaultSRID
        };
        this.state = {
            srid: this.persistedMapProps.srid
        };
    }
    updatePersistedMapProps(persistedMapProps) {
        this.persistedMapProps = { ...this.persistedMapProps, ...persistedMapProps };
        this.props.setMapProps(this.persistedMapProps);
    }
    updateMapSelectedSRID(srid) {
        const { isStationFilterCtrlActive, baseMap, visibleToggles } = this.persistedMapProps;
        this.persistedMapProps = {
            isStationFilterCtrlActive,
            baseMap,
            visibleToggles,
            srid
        };
        // Using srid as key for SearchResultMap forces React to recreate the component when it changes
        this.setState({ srid });
    }
    render() {
        const { specTable, filterTemporal, helpStorage, labelLookup, updateFilter, handleFilterReset, setFilterTemporal, filterPids, setNumberFilter, filterNumbers, keywords, filterKeywords, setKeywordFilter, countryCodesLookup, spatialRects, srid } = this.props;
        const resetBtnEnabled = filterTemporal.hasFilter
            || specTable.hasActiveFilters
            || filterNumbers.hasFilters
            || filterKeywords.length > 0
            || filterPids !== null
            || (!!spatialRects && spatialRects.length > 0);
        return (React.createElement("div", null,
            React.createElement(ResetBtn, { enabled: resetBtnEnabled, resetFiltersAction: handleFilterReset }),
            React.createElement(MapFilter, { key: srid, tabHeader: "Stations map", persistedMapProps: this.persistedMapProps, updatePersistedMapProps: this.updatePersistedMapProps.bind(this), updateMapSelectedSRID: this.updateMapSelectedSRID.bind(this) }),
            React.createElement(PanelsWithFilters, { filterNumbers: filterNumbers, specTable: specTable, helpStorage: helpStorage, labelLookup: labelLookup, countryCodesLookup: countryCodesLookup, updateFilter: updateFilter, setNumberFilter: setNumberFilter, filterTemporal: filterTemporal, setFilterTemporal: setFilterTemporal, keywords: keywords, filterKeywords: filterKeywords, setKeywordFilter: setKeywordFilter, startCollapsed: false })));
    }
}
const ResetBtn = ({ resetFiltersAction, enabled }) => {
    const onClick = () => enabled ? resetFiltersAction() : {};
    const baseStyle = { margin: '7px', fontSize: 14 };
    const style = enabled
        ? { ...baseStyle, cursor: 'pointer' }
        : { ...baseStyle, opacity: 0.5 };
    const title = enabled ? "Reset all filters" : "No active filters";
    return (React.createElement("div", { className: "d-flex justify-content-end" },
        React.createElement("span", { className: "fa-stack fa-1x", onClick: onClick, title: title, style: style },
            React.createElement("i", { className: "fas fa-filter fa-stack-1x" }),
            React.createElement("i", { className: "fas fa-ban fa-stack-2x" }))));
};
function stateToProps(state) {
    return {
        filterTemporal: state.filterTemporal,
        filterNumbers: state.filterNumbers,
        specTable: state.specTable,
        helpStorage: state.helpStorage,
        labelLookup: state.labelLookup,
        countryCodesLookup: state.countryCodesLookup,
        keywords: state.keywords,
        filterKeywords: state.filterKeywords,
        filterPids: state.filterPids,
        spatialRects: state.mapProps.rects,
        srid: state.mapProps.srid
    };
}
function dispatchToProps(dispatch) {
    return {
        updateFilter: (varName, values) => dispatch(specFilterUpdate(varName, values)),
        setFilterTemporal: (filterTemporal) => dispatch(setFilterTemporal(filterTemporal)),
        setNumberFilter: (numberFilter) => dispatch(setNumberFilter(numberFilter)),
        setKeywordFilter: (filterKeywords) => dispatch(setKeywordFilter(filterKeywords)),
        setMapProps: (mapProps) => dispatch(setMapProps(mapProps)),
    };
}
export default connect(stateToProps, dispatchToProps)(Filters);
