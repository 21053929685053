import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { failWithError } from '../../actions/common';
import { Value } from '../../models/SpecTable';
import { Copyright } from 'icos-cp-copyright';
function MapFilter(props) {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isMapLoaded, setMapLoaded] = useState(false);
    const modalRef = useRef(null);
    async function handleMapClick() {
        setModalOpen(true);
    }
    async function handleDiablogCloseButtonClick() {
        setModalOpen(false);
    }
    useEffect(() => {
        const modalElement = modalRef.current;
        if (modalElement) {
            if (isModalOpen) {
                modalElement.showModal();
                if (!isMapLoaded) {
                    loadMap('map-editable', true, props);
                    setMapLoaded(true);
                }
            }
            else {
                modalElement.close();
            }
        }
    }, [isModalOpen]);
    useEffect(() => {
        loadMap('map-non-editable', false, props);
    }, [props]);
    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const handleKeyDown = (event) => {
        if (event.key === "Escape") {
            handleCloseModal();
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "map-non-editable", className: 'map-non-editable' },
            React.createElement("div", { className: 'map-overlay-wrapper position-absolute top-0 start-0 bottom-0 end-0 z-1', onClick: handleMapClick },
                React.createElement("div", { className: 'map-overlay' }),
                React.createElement("i", { className: "map-overlay-icon fas fa-expand-arrows-alt position-absolute top-50 start-50 translate-middle z-2" }))),
        React.createElement("div", { className: "" },
            React.createElement("dialog", { ref: modalRef, className: "modal-dialog modal-xl p-0 map-dialog", onKeyDown: handleKeyDown },
                React.createElement("div", { className: "modal-content" },
                    React.createElement("div", { className: 'modal-header' },
                        React.createElement("h1", { className: "modal-title fs-4" }, "Select regions of interest"),
                        React.createElement("button", { type: "button", className: "btn-close", "data-bs-dismiss": "modal", "aria-label": "Close", onClick: handleDiablogCloseButtonClick })),
                    React.createElement("div", { className: 'modal-body' },
                        React.createElement("div", { id: "map-editable", className: 'map-editable' },
                            React.createElement("div", { id: "stationFilterCtrl", className: "ol-control ol-layer-control-ur", style: { top: 70, fontSize: 20 } }),
                            React.createElement("div", { id: "popover", className: "ol-popup" }),
                            React.createElement("div", { id: "projSwitchCtrl", className: "ol-layer-control ol-layer-control-lr", style: { zIndex: 99, marginRight: 10, padding: 0 } }),
                            React.createElement("div", { id: "layerCtrl", className: "ol-layer-control ol-layer-control-ur" }),
                            React.createElement("div", { id: "attribution", className: "ol-attribution ol-unselectable ol-control ol-uncollapsible", style: { right: 15 } },
                                React.createElement("ul", null,
                                    React.createElement("li", null,
                                        React.createElement(Copyright, null))),
                                React.createElement("ul", null,
                                    React.createElement("li", { id: "baseMapAttribution" }))))))))));
}
function loadMap(elementId, edit, props) {
    (async () => {
        const { default: InitMap } = await import(
        /* webpackMode: "lazy" */
        /* webpackChunkName: "init-map" */
        '../../models/InitMap');
        new InitMap({
            mapRootElement: document.getElementById(elementId),
            allStations: props.allStations,
            stationPos4326Lookup: props.stationPos4326Lookup,
            persistedMapProps: props.persistedMapProps,
            mapProps: props.mapProps,
            updateMapSelectedSRID: props.updateMapSelectedSRID,
            updatePersistedMapProps: props.updatePersistedMapProps,
            labelLookup: props.labelLookup,
            selectedStations: props.selectedStations,
            edit: edit
        });
    })()
        .catch(error => {
        props.failWithError(error);
    });
}
function stateToProps(state) {
    return {
        specTable: state.specTable,
        allStations: state.baseDobjStats.getAllColValues("station").filter(Value.isString),
        stationPos4326Lookup: state.stationPos4326Lookup,
        labelLookup: state.labelLookup,
        selectedStations: state.specTable.origins.getDistinctColValues("station").filter(Value.isString),
        mapProps: state.mapProps
    };
}
function dispatchToProps(dispatch) {
    return {
        failWithError: (error) => failWithError(dispatch)(error),
    };
}
export default connect(stateToProps, dispatchToProps)(MapFilter);
